import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Logo from "./Logo";

export const pages = [
  "Location",
  "Seating Chart",
  "Gallery",
];

const Header: React.FC<Props> = ({ scrollToPanel }) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(null);
    scrollToPanel(event);
  };

  return (
    <AppBar position="fixed" sx={{backgroundColor: "rgba(0,0,0, 0.4)", boxShadow: "none"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ position: "relative", }}>
          <Logo
            scrollToPanel={scrollToPanel}
            sx={{
              mr: 2,  
              ml: { xs: 5, xl: 0 },
              display: { xs: "none", md: "flex" },
            }}
          />
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} id={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" id={page}>
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Logo
            scrollToPanel={scrollToPanel}
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              position: "absolute",
              width: "100%",
              margin: "auto",
              justifyContent: "center",
              zIndex: -2,
            }}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: { xs: "none", md: "right" },
              
              marginRight: {md: 4}
            }}
          >
            {pages.map((page, index) => (
              <Button
                id={page}
                key={index}
                onClick={scrollToPanel}
                sx={{ my: 2, ml: 2, color: "white", display: "block"}}
              >
               <Typography textAlign="center" id={page} fontFamily="serif" fontSize={15} fontWeight={"bold"}>
                    {page}
                  </Typography>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

interface Props {
  scrollToPanel: (event: any) => void;
}
export default Header;
