import { useRef } from "react";
import Header, { pages } from "./Components/Header";
import Location from "./Panels/Location/Location";
import Gallery from "./Panels/Gallery/Gallery";
import MainPanel from "./Panels/MainPanel/MainPanel";
import WeddingSeatingChart from "./Panels/SeatingChart/SeatingChart";

function App() {
  const mainPanelRef = useRef<null | HTMLDivElement>(null);
  const locationRef = useRef<null | HTMLDivElement>(null);
  const galleryRef = useRef<null | HTMLDivElement>(null);
  const seatingChartRef = useRef<null | HTMLDivElement>(null);

  const onScroll = (event: any) => {
    if (mainPanelRef.current && event.target.id === "logo") {
      mainPanelRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (locationRef.current && event.target.id === pages[0]) {
      locationRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (seatingChartRef.current && event.target.id === pages[1]) {
      seatingChartRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    } else if (galleryRef.current && event.target.id === pages[2]) {
      galleryRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <>
     <Header scrollToPanel={onScroll}></Header>
      <div ref={mainPanelRef} style={{backgroundColor: "grey"}}>
        <MainPanel></MainPanel>
      </div>
      <div ref={locationRef} id="location">
        <Location></Location>
      </div>
      <div ref={seatingChartRef}>
        <WeddingSeatingChart></WeddingSeatingChart>
      </div>
      <div ref={galleryRef}>
        <Gallery></Gallery>
      </div>
      </>
  );
}

export default App;
