import { useState } from 'react';
import { Dialog, DialogContent, IconButton, Container, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const WeddingSeatingChart = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (reason) => {
    setOpen(false);
  };

  return (
    <Container maxWidth={false}  sx={{ margin: 0, pb: 10, pt: 3, display: "flex", flexDirection: "column", alignItems: "center", background: "linear-gradient(135deg, #ffffff 0%, #f9f2ec 100%)"}}>
      <Typography
          variant="h4"
          sx={{
            color: "gray",
            fontFamily: "cursive",
            fontSize: 45,
            mt: { xs: 8 },
						textAlign: "center",
            mb: 5,
          }}
        >
          Wedding Seating Chart (mockup)
        </Typography>
			<Box sx={{width: "400px"}} >
      <img 
        src={'Seating_chart.png'} 
        alt="Wedding Seating Chart" 
        style={{ maxWidth: '100%', height: 'auto', cursor: 'pointer' }} 
        onClick={handleClickOpen} 
      />
			</Box>

      <Dialog 
        open={open} 
				onClick={handleClose} 
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            boxShadow: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          },
        }}
      >
        <DialogContent style={{ margin: 2, alignContent: "center"}}>
					
          <img 
            src={'Seating_chart.png'} 
            alt="Wedding Seating Chart" 
            style={{ maxWidth: '100%', maxHeight: '90%', objectFit: 'contain' }} 
          />
        </DialogContent>

        <IconButton 
          onClick={handleClose} 
          style={{ 
            position: 'absolute', 
            top: 10, 
            right: 10, 
            color: 'white',
						zIndex: 2, 
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </Dialog>
    </Container>
  );
};

export default WeddingSeatingChart;
